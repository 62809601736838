<template>
  <navigation index="manageNotices" ref="navigation">
    <div
      :style="{ height: ContentHeight + 'px' }"
      style="width: calc(100% - 5px)"
    >
      <div class="topnavigation" style="justify-content: space-between">
        <el-button @click="AddCommonAdvert({})">新建</el-button>
        <el-button
          style="margin-right: 30px"
          type="primary"
          @click="CommonAdvertListWindow = true"
          >通知列表</el-button
        >
      </div>
      <div class="editorContent">
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="默认" name="默认">
            <div style="display: flex; height: 40px; align-items: center">
              <label style="width: 100px">标题</label>
              <el-input v-model="CommonAdvert.Title"></el-input>
            </div>

            <QuillEditor
              v-model:content="CommonAdvert.Value"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 300px"
              theme="snow"
            ></QuillEditor>

            <div style="margin: 8px; display: flex; gap: 10px">
              <el-date-picker
                v-model="CommonAdvert.StartDate"     value-format="YYYY/MM/DD HH:mm:ss"
                type="date"
                placeholder="开始通知时间"
                :size="size"
              />
              <el-date-picker
                v-model="CommonAdvert.EndDate"     value-format="YYYY/MM/DD HH:mm:ss"
                type="date"
                placeholder="结束通知时间"
                :size="size"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="item.LanguageName"
            :name="item.LanguageName"
            v-for="(item, index) in CommonAdvert.Languages"
            :key="index"
          >
            <div style="display: flex; height: 40px; align-items: center">
              <label style="width: 100px">标题</label>
              <el-input v-model="item.Language.Title"></el-input>
            </div>

            <QuillEditor
              v-model:content="item.Language.Value"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 300px"
              theme="snow"
            ></QuillEditor>
          </el-tab-pane>
        </el-tabs>
        <el-button @click="AddNotices"> 保存 </el-button>
      </div>
    </div>

    <el-drawer
      v-model="CommonAdvertListWindow"
      title="通知列表"
      direction="rtl"
      size="50%"
      style="z-index: 1561565541546561656"
    >
      <el-table :data="CommonAdvertList">
        <el-table-column property="Title" label="标题" width="350" />

        <el-table-column :label="tools.GetLanguageValue('web.操作')">
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="AddCommonAdvert(scope.row)"
            >
              {{ tools.GetLanguageValue("web.编辑") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import {
  UploadFilled,
  Picture as IconPicture,
  Plus,
} from "@element-plus/icons-vue";
import { ElMessageBox, ElMessage } from "element-plus";
import WangEditor from "../../../components/WangEditor.vue";
import navigation from "../../../components/Modules/AdminNavigation.vue";
import tools from "../../../commons/tools.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      editToolbar: [["bold", "italic", "underline", "strike"]],
      HomeCarouselAdvertsDisplay: false,
      TableHeight: document.documentElement.clientHeight - 120,
      ContentHeight: document.documentElement.clientHeight - 200,
      CommonAdvertList: [],
      CommonAdvert: {},
      CommonAdvertListWindow: false,
      activeName: "默认",
      CommonAdvertPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.GetCommonAdvert();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;
  },
  components: {
    WangEditor,
    UploadFilled,
    IconPicture,
    Plus,
    QuillEditor,
    navigation,
  },

  methods: {
    AddNotices() {
      // const editorModel = this.$refs.editorModel;

      // var content = editorModel.editor.txt.html();
      // this.CommonAdvert.Value = content;
      axios.apiMethod(
        "/Admins/Advert/AddNotices",
        "post",
        this.CommonAdvert,
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              type: "success",
              message: "保存成功",
            });
          } else {
            ElMessageBox({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    AddCommonAdvert(item) {
      GetCommonAdvertDetail(item.Id);

      // const editorModel = this.$refs.editorModel;
      // editorModel.editor.txt.html("");
      // editorModel.editor.txt.html(this.CommonAdvert.Value);
    },
    GetCommonAdvert() {
      axios.apiMethod(
        "/Admins/Advert/GetCommonAdvert",
        "post",
        this.CommonAdvertPadding,
        (result) => {
          this.CommonAdvertList = result.Data.Datas;

          if (this.CommonAdvertList.length > 0) {
            this.GetCommonAdvertDetail(this.CommonAdvertList[0].Id);
          }
        }
      );
    },
    GetCommonAdvertDetail(id) {
      axios.apiMethod(
        "/Admins/Advert/GetCommonAdvertDetail",
        "get",
        { id: id },
        (result) => {
          this.CommonAdvert = result.Data;
          // const editorModel = this.$refs.editorModel;
          // editorModel.editor.txt.html("");
          // editorModel.editor.txt.html(result.Data.Value);
        }
      );
    },
  },
};
</script>

<style scoped>
.editorContent {
  height: 100%;
}
</style>
